import { showProgress, removeProgress } from './reusables/showProgressBtn.js';
import { showAlert } from './reusables/alert.js';
import showError from './reusables/showError.js';

const btnUpdate = document.querySelector('.btn-updateMe');
const emailIn = document.getElementById('email-update');
const nameIn = document.getElementById('name-update');
const baseURL = '/api/v1/users';

export default async function (e) {
  try {
    const user = JSON.parse(document.querySelector('.profile-settings').dataset.user);
    e.preventDefault();
    const email = emailIn.value.trim() || user.email;
    const name = nameIn.value.trim() || user.name;

    showProgress(btnUpdate);
    await axios.post(`${baseURL}/updateMe`, { email, name });
    showAlert('success', 'Update successful.');

    removeProgress(btnUpdate, 'Updated');

    setTimeout(() => {
      location.reload(true);
    }, 3000);
  } catch (err) {
    showError(err, btnUpdate, 'Save');
  }
}

export async function deleteAcc(e) {
  const btnDeleteAcc = e.target.closest('.btn-delete-account');
  const btnInnerHtml = btnDeleteAcc.innerHTML;
  if (!btnDeleteAcc) return;

  try {
    showProgress(btnDeleteAcc);
    const res = await axios.delete(`${baseURL}/delete-me`);
    removeProgress(btnDeleteAcc, 'Deleted');
    btnDeleteAcc.setAttribute('disabled', true);

    showAlert('success', 'Success on account deletion');

    setTimeout(() => {
      location.assign('/');
    }, 1500);
  } catch (err) {
    showError(err, btnDeleteAcc, btnInnerHtml);
  }
}

import { showAlert } from './reusables/alert.js';
import makeRequest from './reusables/fetch.js';
import showError from './reusables/showError.js';
import { showProgress } from './reusables/showProgressBtn.js';

const modalCodes = document.getElementById('modal-codes');
const addCodeInput = modalCodes?.querySelector('.btn-add-code');
const formCodes = modalCodes?.querySelector('.form-codes');

export function handleCodesModal(e) {
  e.preventDefault();
  const btnUpgrade = modalCodes.querySelector('.upgrade-with-codes');
  handleUpgrade(btnUpgrade);
}

async function handleUpgrade(btnUpgrade) {
  addCodeInput.setAttribute('disabled', true);

  try {
    const codes = [];
    const codeInputes = modalCodes.querySelectorAll('.input-code');

    codeInputes.forEach((inp) => {
      codes.push(inp.value.trim());
    });

    const validCodes = codes.filter((code) => code.trim() !== '');

    if (validCodes.length === 0)
      return showAlert('danger', 'All code inputes are empity. Please provide codes to upgrade.');

    showProgress(btnUpgrade);

    const data = await makeRequest({
      url: '/api/v1/users/subscribe-via-code',
      method: 'post',
      dataTobeSent: { codes: validCodes },
    });

    showAlert(data.status, data.message);
    btnUpgrade.innerHTML = 'success';
  } catch (err) {
    showError(err, btnUpgrade, 'Try Again!');
  } finally {
    btnUpgrade.removeAttribute('disabled');
    addCodeInput.removeAttribute('disabled');
    setTimeout(() => {
      btnUpgrade.innerHTML = 'upgrade';
      addCodeInput.removeAttribute('disabled');
    }, 2000);
  }
}

const inputHTML = `<div class='update-input-container-codes mb-3'>
                        <input class='form-control input-code' type='text' placeholder='Code here...' minLength='5' />
                        <button class='btn btn-tool-add-code btn-outline-primary btn-remove-code' type='button'>-</button>
                    </div>`;

export function handleAddCode(e) {
  if (e.target.closest('.btn-add-code')) return formCodes.insertAdjacentHTML('afterbegin', inputHTML);
  if (e.target.closest('.btn-remove-code')) return e.target.closest('.update-input-container-codes').remove();
}

modalCodes?.addEventListener('hide.bs.modal', () => {
  modalCodes.querySelectorAll('input').forEach((inp) => {
    inp.value = '';
  });
});
